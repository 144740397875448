import './HomePage.css';
import coverpage from './Images/coverpage.jpeg';
import idf from './Images/idf.jpg';
import deposit from './Images/deposit.jpg';
import DonationPopup from './DonationPopup';
import { useState } from 'react';

function HomePage() {
  const [copySuccess, setCopySuccess] = useState('');

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = deposit;
    link.download = 'deposit.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText('1KUidBAgeURh1ksQri9Hv3Emdd3uvapSSE').then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => {
        setCopySuccess('');
      }, 2000);
    }, (err) => {
      setCopySuccess('Failed to copy!');
    });
  };

  return (
    <div className="App">
      <nav className="navbar">
        <ul className="nav-list">
          <li className="nav-item"><a href="#content">Home</a></li>
          <li className="nav-item donate-now"><a href="#donate-now" className="red-link">Donate-Now</a></li>

          <li className="nav-item dropdown">
          <a href="#about-us" className="dropbtn">About</a>
            <div className="dropdown-content">
              <a href="#about-us">About Us</a>
              <a href="#job-opportunities">Job Opportunities</a>
            </div>
          </li>
          <li className="nav-item"><a href="#how-your-donation">How Your Donation Will Help</a></li>
        </ul>
      </nav>

      <div className="content" id='content'>
        <img src={coverpage} alt="coverpage" className='cover-img' />
      </div>

      <div className="header">
        <h1>Now or Never. Let us get our Home back.</h1>
      </div>

      <div className='content'>
        <p className="verse"><strong>"For the Lord your God is the one who goes with you to fight for you against your enemies to give you victory." - Deuteronomy 20:4</strong></p>
        <div id="donate-now" className='donatenow'>
          <h2>Donate Now</h2>
          <p>Support our cause by donating now. Every contribution helps us get closer to our Land.</p>
          <p>Right now, we are only accepting binance BTC payments. More payment gateways will be announced soon.</p>
          <p>Copy the btc address or press donate to download the qr code.</p>
          <p>BTC address:</p>
          <p className='btc-address'>1KUidBAgeURh1ksQri9Hv3Emdd3uvapSSE</p>
          <button className='copy-button' onClick={handleCopy}>Copy</button>
          {copySuccess && <span className="copy-success">{copySuccess}</span>}
          <button className='donate-button' onClick={handleDownload}>Donate</button>
        </div>
      </div>
      <div className="two-column-container" id="about-us">
        <div className='content-about'>
          <h2>About Us</h2>
          <p>We are an organization working since 2016. We have grown into one of the largest aid organizations in Israel. We collaborate with communities that have the same goal, including IDF, IAF, MOSSAD, ISRAAID.</p>
        </div>
        <div className='content-job-opportunities' id='job-opportunities'>
          <h2>Job Opportunities</h2>
          <p>No current opportunities are open. We'll let you know.</p>
        </div>
      </div>
      <div className='two-column-container' id="how-your-donation">
        <div className='content-d'>
          <h2>How Your Donation Will Help</h2>
          <p>We collaborate with communities that are fighting on the front line. Your donation helps provide essential resources, support, and assistance to those who need it most. Together, we can make a difference in the lives of countless individuals and contribute to the greater good of our society. Our first priority are our front-line soldiers. Providing everything they want. From ammunation to Aid. We provide everything they need.</p>
        </div>
        <div className='content-d'>
          <img src={idf} alt="IDF" className='idf-img' />
        </div>
      </div>
      <div className='thank-you-box'>
        <div className='content-d'>
          <div className='thank-you-content'>
            <h2>Thank you for your donation</h2>
            <p>We're on the brink of reclaiming our homeland, and every contribution brings us closer to our goal. Whether you reside in Israel or beyond, your support is invaluable in restoring what rightfully belongs to us. Together, we can make a profound impact and pave the way for a brighter future. Join us in fulfilling this shared mission, as it's not just about land; it's about reclaiming our heritage and securing a better tomorrow for generations to come. Your generosity is more than a donation; it's a commitment to justice, unity, and the preservation of our identity. Together, let's stand as one and ensure that our voices are heard, and our dreams are realized.</p>
          </div>
        </div>
      </div>
      <DonationPopup />
    </div>
  );
}

export default HomePage;
