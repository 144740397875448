import React, { useState, useEffect } from 'react';
import './DonationPopup.css';

const DonationPopup = () => {
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const showPopup = () => {
      const randomAmount = Math.floor(Math.random() * ((3000 / 10) - (200 / 10) + 1)) * 10 + 200;
      setAmount(randomAmount);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000); // Show popup for 3 seconds
    };

    const interval = setInterval(showPopup, 5000); // Show popup every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`popup ${show ? 'show' : ''}`}>
      ${amount} donated
    </div>
  );
};

export default DonationPopup;